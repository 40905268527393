import axios from '../../plugins/axios'
import * as type from '../reducers/types'
import { apiUrl, getAuth } from './utils'

// export const getLimnimeters = (user) => async dispatch => {
//     dispatch({
//         type: type.LOADING,
//         load: true
//     })
//     const urlLimnimeters = `${apiUrl}/limnimeters`

//     // Llamado a la API para la cosigna Actual

//     const urlConsigna = `${apiUrl}/acm-infos`;
//     const { data: consignaData } = await axios.get(urlConsigna, getAuth());
//     const consigna = parseFloat(consignaData[0].acm_regulation.value.replace("%", ""));

//     console.log(consigna)

//     dispatch({
//         type: type.GET_INFOCARDS,
//         infoCards: consigna
//     })

//     const findChannels = (firstArray, secondArray) => {
//         if (firstArray && secondArray) {
//             // Crear un conjunto (Set) para almacenar los códigos de los canales del primer arreglo
//             const setFirstArray = new Set(firstArray.map(channel => channel.code));

//             // // Filtrar el segundo arreglo, manteniendo solo los canales cuyos códigos estén presentes en el conjunto
//             const channelsMatch = secondArray.filter(channel => setFirstArray.has(channel.code));
//             return channelsMatch;
//         }
//         return []
//     }



//     return axios.get(urlLimnimeters, getAuth())
//         .then(({ data }) => {

//             console.log(data)
//             // Creamos un Set para almacenar los códigos de canal únicos
//             const channelsUnique = new Set();

//             // Iteramos sobre cada limnimetro
//             const channels = data.map(limnimetro => {
//                 // Obtenemos el código del canal
//                 const codChannel = limnimetro.channels.code;
//                 // Si el código no está en el Set, lo agregamos y retornamos el canal
//                 if (!channelsUnique.has(codChannel)) {
//                     channelsUnique.add(codChannel);
//                     limnimetro.channels.curve = limnimetro?.curve || []
//                     limnimetro.channels.state = limnimetro?.state
//                     limnimetro.channels.formula = limnimetro?.formula || []
//                     return limnimetro.channels
//                 } else {
//                     return null
//                 }
//             }
//             )
//             // Canales filtrados por su estado Activo
//             let channelsActive = data.filter(x => x.state === true)

//             // Lógica para determinar la lista de canales correspondientes a los Celadores y otros Roles
//             let result;
//             switch (user?.association_area?.code) {
//                 case "watchman":
//                     result = findChannels(user.channels, channelsActive);
//                     break;
//                 case "sysadmin":
//                 case "communications":
//                 case "operationsm":
//                 case "fieldm":
//                     result = channelsActive;
//                     break;
//                 default:
//                     result = [];
//                     break;
//             }
//             dispatch({
//                 type: type.GET_LIMNIMETERS,
//                 dash: result
//             })
//         }).finally(() => {
//             dispatch({
//                 type: type.LOADING,
//                 load: false
//             })
//         })
// }

export const getLimnimeters = (user) => async dispatch => {

    dispatch({ type: type.LOADING, load: true });

    const urlLimnimeters = `${apiUrl}/limnimeters`;
    const urlConsigna = `${apiUrl}/acm-infos`;

    try {

        // // Obtener los Limnimeters y Consigna
        const [limnimeterResponse, consignaResponse] = await Promise.all([
            axios.get(urlLimnimeters, getAuth()),
            axios.get(urlConsigna, getAuth())
        ]);
        const limnimeterData = limnimeterResponse.data;
        const consignaData = consignaResponse.data;

        const consigna = parseFloat(consignaData[0].acm_regulation.value.replace("%", ""));


        dispatch({
            type: type.GET_INFOCARDS,
            infoCards: consigna
        })

        const channelsActive = limnimeterData.filter((x) => x.state === true);


        // Obtener Canales del Usuario que tienen Limnimetro
        const findChannels = (firstArray, secondArray) => {
            console.log(firstArray, secondArray)
            if (firstArray && secondArray) {
                // Crear un conjunto (Set) para almacenar los códigos de los canales del primer arreglo
                const setFirstArray = new Set(firstArray.map(channel => channel.code));
                // // Filtrar el segundo arreglo, manteniendo solo los canales cuyos códigos estén presentes en el conjunto
                return secondArray.filter(channel => setFirstArray.has(channel.code));
            }
            return []
        }

        let result;

        switch (user?.association_area?.code) {
            case "watchman":
                result = findChannels(user.channels, channelsActive);
                break;
            case "sysadmin":
            case "communications":
            case "operationsm":
            case "fieldm":
                result = channelsActive;
                break;
            default:
                result = [];
        }
        dispatch({
            type: type.GET_LIMNIMETERS,
            dash: result
        })
    } catch (err) {
        dispatch({ type: type.ERROR, error: "Error al cargar Limnimetros" })
        return null
    } finally {
        dispatch({ type: type.LOADING, load: false });
    }

}