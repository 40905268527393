import styled from 'styled-components'

export const Content = styled.div` position: relative;

.stat-card {
  .error {
    border: 0.1rem solid red !important
  }

  .error-message {
    color: red
  }

  .component-disabled {
    opacity: 0.5;
    pointer-events: none;
    /* Evita que se capturen eventos */
  }

  margin: 1rem auto;

  form {
    text-align: left;

    h1 {
      font-size: 18px;
      // color: ${({ theme }) => theme.secondary};
    }

    .form-limnimeter {
      margin: 1rem 0;

      .form-channel {
        select {
          width: 100%;
          height: auto;
          border: 1px solid #b5b5b5;
          border-radius: 7px;
          padding: 10px;
          // color: #b5b5b5;
          margin: 0.5rem 0 0.2rem 0;
        }
      }
    }

    .form-measurement {
      margin: 1rem 0;

      .form-measurement-broken {
        display: flex;
        gap: 1em;

        h5 {
          align-self: center;
        }
      }

      .form-measurement-content {
        display: flex;
        margin: 1rem 0;

        select {
          width: 100%;
          height: auto;
          border: 1px solid #b5b5b5;
          border-radius: 7px;
          padding: 10px;
          // color: #b5b5b5;
          margin: 0.5rem 0 1rem 0;
        }

        .form-measurement-input {
          width: 70%
        }

        .form-measurement-result {
          width: 30%;
          justify-items: center;
          align-content: center;

          h3 {
            background: #257dde;
            color: white;
            padding: 0.5em;
          }
        }
      }
    }

    .form-photo {
      margin: 1rem 0;

      .form-photo-add-button {
        width: fit-content;
        height: 75px;
        border: 1px solid #a4a4a4;
        border-radius: 15px;
        padding: 18px;
        margin: 1rem 1rem 3rem 1rem;
      }

      .form-photo-icon {
        i {
          font-size: 80px;
        }
      }

      .form-photo-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #257dde;
        color: white;
        border-radius: 100px;
        position: relative;
        left: 95%;
        bottom: 15%;
        width: 50px;
        height: 50px;

        i {
          font-size: 24px;
        }
      }

      .form-photo-image {
        .form-photo-image-delete {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid gray;
          background: white;
          color: gray;
          border-radius: 100px;
          position: relative;
          left: 55%;
          top: 18px;
          width: 35px;
          height: 35px;

          i {
            font-size: 24px;
          }
        }

        img {
          width: 60%;
          height: auto;
          object-fit: contain;
          -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
        }
      }

      button {
        background: transparent;
        border: 1px solid #b5b5b5;
        padding: 30px 10px;
        margin: 1rem 0;
        border-radius: 6px;
      }
    }

    .form-incidents {

      input[type="checkbox"] {
        display: none;
      }

      /* Estilizar el elemento personalizado */
      .checkmark {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border: 2px solid #ccc;
        border-radius: 3px;
      }

      /* Estilizar el checkbox cuando está marcado */
      input[type="checkbox"]:checked+.checkmark {
        background-color: #4CAF50;
      }

      margin: 1rem 0;

      .form-incidents-flex {
        display: flex;
        flex-direction: column;
        gap: 1em;
        justify-content: space-evenly;
        margin: 0.5rem 0 1rem 0;
      }
    }

    .form-observation {
      margin: 1rem 0;

      input {
        margin: 0.5rem 0 1rem 0;
        width: 100%;
        min-height: 70px;
        border: 1px solid #b5b5b5;
        border-radius: 6px;
        padding: 0;
      }
    }

    .form-button-add {
      display: flex;
      justify-content: center;

      button {
        background: #257dde;
        color: white;
        padding: 8px;
        border: 1px solid transparent;
      }
    }
  }
}
}

//   @media all and (min-width: 1280px) {
//     max-width: 1000px;
//     margin: 0 auto;
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     #card {
//       width: calc(100% - 3rem);
//     }
//   }
`
export const Prueba = styled.div`

/* Base for label styling */
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: 0;
  opacity: 0.01;
}

[type='checkbox']:not(:checked)+label,
[type='checkbox']:checked+label {
  position: relative;
  padding-left: 1.8em;
  font-size: 1.05em !important;
  line-height: 1.7;
  cursor: pointer;
}

/* checkbox aspect */
[type='checkbox']:not(:checked)+label:before,
[type='checkbox']:checked+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.4em;
  height: 1.4em;
  border: 1px solid #aaa;
  background: #fff;
  border-radius: 1em;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
    0 0 0 rgba(203, 34, 237, 0.2);
  -webkit-transition: all 0.275s;
  transition: all 0.275s;
}

/* checked mark aspect */
[type='checkbox']:not(:checked)+label:after,
[type='checkbox']:checked+label:after {
  // content: '✕';
  content: '\f058';
  font-family: 'fontAwesome';
  position: absolute;
  top: 0.525em;
  left: 0;
  font-size: 1.5em;
  color: #257dde;
  line-height: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

/* checked mark aspect changes */
[type='checkbox']:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0) rotate(45deg);
  transform: scale(0) rotate(45deg);
}

[type='checkbox']:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0);
  transform: scale(1) rotate(0);
}

/* Disabled checkbox */
[type='checkbox']:disabled:not(:checked)+label:before,
[type='checkbox']:disabled:checked+label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #e9e9e9;
}

[type='checkbox']:disabled:checked+label:after {
  color: #777;
}

[type='checkbox']:disabled+label {
  color: #aaa;
}

/* Accessibility */
[type='checkbox']:checked:focus+label:before,
[type='checkbox']:not(:checked):focus+label:before {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1),
    0 0 0 6px rgba(203, 34, 237, 0.2);
}

.btn.btn {
  display: inline-block;
  padding: 8px 24px;
  text-decoration: none;
  border-radius: 40px;
  background: #34495e;
  color: #f2f2f2;
  transition: all 0.4s;
}

.btn.btn:hover,
.btn.btn:focus {
  background: #000;
}

`
export const DetailData = styled.div` text-align: left;
  .no-visible{
    opacity: 0;
  }
.detail-label {
  h3 {
    font-weight: 500;
    color: gray;
  }
}

.detail-data {
  h3 {
    color: #2b3c5c;
  }
}


.detail-header {
  margin: 1em 0;

  h3 {
    color: gray;
  }
}

.detail-content {
  margin: 1em 0;
  background: #f1f2f4;
  padding: 1em .5em 2em;


  .detail-content-body {
    background: white;
    padding: 1em;

    .detail-content-head {
      padding: 1em 0;

      .detail-content-title {
        h3 {
          color: #2b3c5c;
        }
      }
    }

    .detail-info {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;

      .detail-info-item {
        width: 50%;
        padding: 1em 0;

      }
    }

    .detail-photo {
      margin: 1em 0;
    }

    .detail-incidents {
      margin: 1em 0;
      .detail-incidents-content {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .detail-incidents-data {
          background: ${({ theme }) => theme.primary};
          padding: 0.2em 1em;
          margin-top: .8em;
          border-radius: 10px;

          h3 {
            color: white;
            font-weight: 500;
          }
        }
      }
    }

    .detail-observations {
      margin: 1em 0;
    }
  }

  .detail-content-footer{
    margin: 1em 0 0;
    .detail-button {
      display: flex;
      justify-content: center;
      
      button {
        background: #257dde;
        color: white;
        padding: 8px;
        border: 1px solid transparent;
        }
      }
    }

}

`
export const SwitchComponent = styled.div` margin: 1em 0;

.checkbox-wrapper-2 .ikxBAC {
  appearance: none;
  background-color: #c9cbcd;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
  bottom: -6px;
  content: '';
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  background-color: #fff;
  border-radius: 50%;
  content: '';
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper-2 input[type='checkbox'] {
  cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
  cursor:pointer;
}

.checkbox-wrapper-2 .ikxBAC:checked {
  background-color: ${({ theme }) => theme.primary};
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #fff;
  left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
  background-color: ${({ theme }) => theme.primary};
}

`